import { useLazyQuery, useMutation, useReactiveVar } from "@apollo/client";
import styled from "@emotion/styled";
import { OutlinedIcon } from "@gm-components/common/icons";
import { Dialog } from "@headlessui/react";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import LoadingSpin from "react-loading-spin";

import { CURRENT_ACCOUNT } from "../../graphql/localStates/account";
import { SEND_MINDBODY_SITE_ACTIVATION } from "../../graphql/mutations/mindbody";
import { CHECK_MINDBODY_PAYWALL_ACTIVATION, CHECK_MINDBODY_SITE_ACTIVATION } from "../../graphql/queries/mindbody";
import convertComponent from "../../lib/convert-component";
import { colors } from "../../styles/theme";
import { LargeDarkButton, LargeOutlinedButton, SmallDarkButton, SmallOutlinedButton } from "./buttons";
import CustomIcon from "./custom-icon";
import { TextInput } from "./forms";
import MindbodyPaywallConfirmModal from "./mindbody-paywall-confirm-modal";
import { Subheading } from "./typography";

const PlatformLink = styled("a")`
  padding-left: 0;
  padding-right: 0;
`;

const OutlinedLink = convertComponent(LargeOutlinedButton, PlatformLink);
const DarkLink = convertComponent(LargeDarkButton, PlatformLink);

const OutlinedButton = PlatformLink.withComponent(LargeOutlinedButton);
const DarkButton = styled(PlatformLink.withComponent(LargeDarkButton))`
  gap: 24px;
`;

const Content = styled.div`
  width: 240px;
  display: flex;
  align-items: center;
  gap: 24px;
`;

const GoogleLink = ({ cta, href }) => (
  <OutlinedLink color="gray" href={href} shade={900}>
    <Content>
      <CustomIcon color="multi" name="google" />
      {cta}
    </Content>
  </OutlinedLink>
);

const GoogleButton = ({ cta, onClick }) => (
  <OutlinedButton color="gray" onClick={onClick} shade={600} disabled>
    <Content>
      <CustomIcon color="multi" name="google" />
      {cta}
    </Content>
  </OutlinedButton>
);

const InstagramButton = ({ cta, onClick }) => (
  <OutlinedButton color="gray" onClick={onClick} shade={900}>
    <Content>
      <CustomIcon color="multi" name="instagram" />
      {cta}
    </Content>
  </OutlinedButton>
);

const FacebookLink = ({ cta, href }) => (
  <DarkLink backgroundColor="facebook" backgroundShade={700} color="white" href={href}>
    <Content>
      <CustomIcon color="white" name="facebook" />
      {cta}
    </Content>
  </DarkLink>
);

const FacebookButton = ({ cta, onClick }) => (
  <DarkButton backgroundColor="facebook" backgroundShade={700} color="white" onClick={onClick}>
    <Content>
      <CustomIcon color="white" name="facebook" />
      {cta}
    </Content>
  </DarkButton>
);

const TwitterLink = ({ cta, href }) => (
  <DarkLink backgroundColor="twitter" backgroundShade={700} color="white" href={href}>
    <Content>
      <CustomIcon color="white" name="twitter" />
      {cta}
    </Content>
  </DarkLink>
);

const TwitterButton = ({ cta, onClick }) => (
  <DarkButton backgroundColor="twitter" backgroundShade={700} color="white" onClick={onClick}>
    <Content>
      <CustomIcon color="white" name="twitter" />
      {cta}
    </Content>
  </DarkButton>
);

const LinkedinLink = ({ cta, href }) => (
  <OutlinedLink color="linkedIn" href={href} shade={800}>
    <Content>
      <CustomIcon color="custom" name="linkedin" />
      {cta}
    </Content>
  </OutlinedLink>
);

const LinkedinButton = ({ cta, onClick }) => (
  <OutlinedButton color="linkedIn" onClick={onClick} shade={600} disabled>
    <Content>
      <CustomIcon color="custom" name="linkedin" />
      {cta}
    </Content>
  </OutlinedButton>
);

const TikTokLink = ({ cta, href }) => (
  <OutlinedLink color="gray" href={href} shade={900}>
    <Content>
      <CustomIcon color="black" name="tiktok" />
      {cta}
    </Content>
  </OutlinedLink>
);

const TikTokButton = ({ cta, onClick }) => (
  <OutlinedButton color="gray" onClick={onClick} shade={600} disabled>
    <Content>
      <CustomIcon color="black" name="tiktok" />
      {cta}
    </Content>
  </OutlinedButton>
);

const GymSalesButton = ({ cta, onClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <OutlinedButton color="gray" onClick={() => setIsOpen(true)} shade={900}>
        <Content>
          <CustomIcon color="multi" name="gymsales" />
          {cta}
        </Content>
      </OutlinedButton>
      {isOpen && <GymSalesDialog isOpen={isOpen} setIsOpen={setIsOpen} onClick={onClick} />}
    </>
  );
};

const GymLeadsButton = ({ cta, onClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <OutlinedButton color="gray" onClick={() => setIsOpen(true)} shade={900}>
        <Content>
          <CustomIcon color="multi" name="gymleads" />
          {cta}
        </Content>
      </OutlinedButton>
      {isOpen && <GymLeadsDialog isOpen={isOpen} setIsOpen={setIsOpen} onClick={onClick} />}
    </>
  );
};

const GymSalesDialog = ({ isOpen, setIsOpen, onClick }) => {
  const [email, setEmail] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    onClick(e, { email: e.target.name.value });
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) return;
    setEmail("");
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="relative z-40">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <Dialog.Panel className="mx-auto w-full max-w-md rounded bg-white p-4">
            <form onSubmit={onSubmit} className="flex flex-col gap-2">
              <Subheading>Connect GymSales</Subheading>
              <div className="relative">
                <TextInput
                  className="w-full"
                  autoFocus
                  name="name"
                  placeholder="Email"
                  required
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="flex gap-2">
                <SmallOutlinedButton onClick={() => setIsOpen(false)} type="button" className="ml-auto">
                  Cancel
                </SmallOutlinedButton>
                <SmallDarkButton type="submit">Connect</SmallDarkButton>
              </div>
            </form>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
};

const GymLeadsDialog = ({ isOpen, setIsOpen, onClick }) => {
  const [email, setEmail] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    onClick(e, { email: e.target.name.value });
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) return;
    setEmail("");
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="relative z-40">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <Dialog.Panel className="mx-auto w-full max-w-md rounded bg-white p-4">
            <form onSubmit={onSubmit} className="flex flex-col gap-2">
              <Subheading>Connect GymLeads</Subheading>
              <div className="relative">
                <TextInput
                  className="w-full"
                  autoFocus
                  name="name"
                  placeholder="Email"
                  required
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="flex gap-2">
                <SmallOutlinedButton onClick={() => setIsOpen(false)} type="button" className="ml-auto">
                  Cancel
                </SmallOutlinedButton>
                <SmallDarkButton type="submit">Connect</SmallDarkButton>
              </div>
            </form>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
};

const MindBodyDialog = ({ isOpen, setIsOpen, onClick }) => {
  const [show, setShow] = useState(true);
  const [siteId, setSiteId] = useState("");
  const [showPaywall, setShowPaywall] = useState(false);
  const [isActivated, setIsActivated] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [isActivationSent, setIsActivationSent] = useState(false);
  const accountId = useReactiveVar(CURRENT_ACCOUNT);
  const [checkSiteActivation, { loading: checkActivationLoading, data: siteActivation }] = useLazyQuery(
    CHECK_MINDBODY_SITE_ACTIVATION,
    { fetchPolicy: "network-only" }
  );

  const [sendMbActivation, { loading: sendActivationLoading }] = useMutation(SEND_MINDBODY_SITE_ACTIVATION, {
    variables: { siteId },
  });

  const [checkPaywallActivation, { loading: checkPaywallActivationLoading }] = useLazyQuery(
    CHECK_MINDBODY_PAYWALL_ACTIVATION,
    { fetchPolicy: "network-only" }
  );

  const onSubmit = async (e) => {
    e.preventDefault();
    const data = await checkPaywallActivation({ variables: { siteId, accountId } });
    if (data?.error) {
      toast.error("An error occurred. try again later.");
      return;
    }
    if (
      data?.data?.checkMindbodyPaywallActivation?.isActivated &&
      data?.data?.checkMindbodyPaywallActivation?.siteId === siteId
    ) {
      setShow(false);
      setShowPaywall(true);
    } else {
      onClick(e, { siteId });
      setIsOpen(false);
    }
  };

  const submitWithPaywall = async (e) => {
    onClick(e, { siteId });
    setIsOpen(false);
  };

  const onCancel = () => {
    setSiteId("");
    setIsOpen(false);
  };

  const checkActivation = async () => {
    const result = await checkSiteActivation({ variables: { siteId } });
    const data = result?.data;
    setIsActivated(data?.checkMindbodySiteActivation?.isActivated);
    setIsValid(data?.checkMindbodySiteActivation?.isValid);
  };

  const sendActivation = async () => {
    await sendMbActivation();
    setIsActivationSent(true);
    setTimeout(() => {
      setIsActivationSent(false);
    }, 3000);
  };

  const isInvalid = useMemo(() => {
    return !isValid && !checkActivationLoading && siteId === siteActivation?.checkMindbodySiteActivation?.siteId;
  }, [siteId, isValid, checkActivationLoading, siteActivation?.checkMindbodySiteActivation]);

  const isActivationSendable = useMemo(() => {
    return (
      siteId &&
      isValid &&
      !isActivated &&
      !checkActivationLoading &&
      siteId === siteActivation?.checkMindbodySiteActivation?.siteId
    );
  }, [siteId, isValid, isActivated, checkActivationLoading, siteActivation?.checkMindbodySiteActivation]);

  return (
    <>
      <Dialog open={show} onClose={() => setIsOpen(false)} className="relative z-40">
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Dialog.Panel className="mx-auto w-full max-w-md rounded bg-white p-4">
              <form onSubmit={onSubmit} className="flex flex-col gap-2">
                <Subheading>Connect Mindbody</Subheading>
                <div className="relative">
                  <TextInput
                    className="w-full"
                    autoFocus
                    name="name"
                    placeholder="Site Id"
                    required
                    value={siteId}
                    onChange={(e) => setSiteId(e.target.value)}
                  />
                  {checkActivationLoading && (
                    <div className="absolute right-1 top-1/2 flex -translate-y-1/2 items-center pr-2">
                      <LoadingSpin primaryColor={colors.purple[1000]} size={20} width={2} />
                    </div>
                  )}
                  {isInvalid && (
                    <div className="absolute right-1 top-1/2 flex -translate-y-1/2 items-center pr-2">
                      <OutlinedIcon
                        name="warning"
                        color="red"
                        shade={800}
                        className="cursor-pointer"
                        data-tooltip-id="tooltip"
                        data-tooltip-content="Supplied Site Id is invalid. Please check and try again."
                      />
                    </div>
                  )}
                  {!checkActivationLoading && isActivated && isValid && (
                    <div className="absolute right-1 top-1/2 flex -translate-y-1/2 items-center pr-2">
                      <OutlinedIcon
                        name="check_circle"
                        color="green"
                        shade={800}
                        className="cursor-pointer"
                        data-tooltip-id="tooltip"
                        data-tooltip-content="Site Id is activated."
                      />
                    </div>
                  )}
                  {isActivationSendable && (
                    <div className="absolute right-1 top-1/2 flex -translate-y-1/2 items-center pr-2">
                      {!isActivationSent ? (
                        <SmallDarkButton onClick={sendActivation} disabled={sendActivationLoading}>
                          Email Activation Code
                        </SmallDarkButton>
                      ) : (
                        <div className="flex items-center gap-1 font-semibold text-green-700">
                          Sent
                          <OutlinedIcon
                            name="check_circle"
                            color="green"
                            shade={700}
                            className="cursor-pointer"
                            data-tooltip-id="tooltip"
                            data-tooltip-content="Site Id is activated."
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="flex gap-2">
                  <SmallOutlinedButton onClick={() => setIsOpen(false)} type="button" className="ml-auto">
                    Cancel
                  </SmallOutlinedButton>
                  {!isActivationSendable && !isActivated && (
                    <SmallDarkButton onClick={checkActivation} disabled={checkActivationLoading}>
                      Check Activation
                    </SmallDarkButton>
                  )}
                  <SmallDarkButton
                    type="submit"
                    className={`${checkPaywallActivationLoading ? "disabled:cursor-wait" : ""}`}
                    disabled={!isActivated || checkPaywallActivationLoading}
                  >
                    Connect
                  </SmallDarkButton>
                </div>
              </form>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
      <MindbodyPaywallConfirmModal show={showPaywall} onConfirm={submitWithPaywall} onCancel={onCancel} />
    </>
  );
};

const MindbodyButton = ({ cta, onClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <OutlinedButton color="gray" onClick={() => setIsOpen(true)} shade={900}>
        <Content>
          <CustomIcon color="black" name="mindbody" />
          {cta}
        </Content>
      </OutlinedButton>
      {isOpen && <MindBodyDialog isOpen={isOpen} setIsOpen={setIsOpen} onClick={onClick} />}
    </>
  );
};

export {
  GoogleLink,
  GoogleButton,
  InstagramButton,
  FacebookLink,
  FacebookButton,
  TwitterLink,
  TwitterButton,
  LinkedinLink,
  LinkedinButton,
  TikTokLink,
  TikTokButton,
  MindbodyButton,
  GymSalesButton,
  GymLeadsButton,
};
