import { OutlinedIcon } from "@gm-components/common/icons";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

import mbBackground from "../../assets/images/platforms/mb-background.jpeg";
import mbFullLogo from "../../assets/images/platforms/mindbody-full-logo.svg";
import Button from "./tw-components/buttons";

const MindbodyPaywallConfirmModal = ({ show, onCancel, onConfirm }) => {
  const closeModal = (e) => {
    onCancel?.(e);
  };

  const confirm = async (e) => {
    onConfirm?.(e);
  };

  return (
    <Transition appear show={show} as={Fragment}>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-xl overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                  <div className="">
                    <div className="relative h-44">
                      <img src={mbFullLogo} alt="" className="absolute bottom-5 left-8" />
                      <img src={mbBackground} alt="" className=" size-full object-cover" />
                    </div>
                    <div className="p-6">
                      <h3 className="sub-heading ">Supercharge Your Business with Mindbody</h3>
                      <p className="body-small mt-3">
                        Integrate your Mindbody account with GrowMetrics for $49 USD/month and automatically sync your
                        leads as soon as they come in, saving manual data entry, reducing errors and providing deeper
                        insights into your marketing.
                      </p>
                      <div className="mt-6 flex items-center justify-between gap-1">
                        <div className="mini-bold flex items-center justify-center text-blue-800">
                          Why do I have to pay?
                          <div
                            className="ml-1 flex cursor-pointer items-center justify-center"
                            data-tooltip-id="tooltip"
                            data-tooltip-place="top"
                            data-tooltip-content="We offer the Mindbody integration as a premium feature because it requires ongoing costs on our end to maintain, including fees for API calls. The small charge helps us cover these costs while ensuring the integration runs smoothly for your business."
                          >
                            <OutlinedIcon name="info" color="blue" shade={700} size="18px" />
                          </div>
                        </div>
                        <div className="flex items-center gap-2">
                          <Button type="outline" size="medium" rounded onClick={closeModal}>
                            Cancel
                          </Button>
                          <Button type="solid" size="medium" className="disabled:cursor-wait" rounded onClick={confirm}>
                            Integrate with Mindbody
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </div>
    </Transition>
  );
};

export default MindbodyPaywallConfirmModal;
