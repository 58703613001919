import { ThemeProvider } from "@emotion/react";
import * as Sentry from "@sentry/react";
import { Suspense, lazy } from "react";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { FullWindowLoader } from "./components/common/loaders";
import Secondary from "./components/user/secondary";
import paths from "./config/paths";
import Account from "./layouts/account";
import Auth from "./layouts/auth";
import Onboarding from "./layouts/onboarding";
import PayingUser from "./layouts/payingUser";
import TwoPanel from "./layouts/two-panel";
import Unauth from "./layouts/unauth";
import Global from "./styles/global";
import * as theme from "./styles/theme";

const Signup = lazy(() => import("./pages/signup"));
const Login = lazy(() => import("./pages/login"));

const UserAccounts = lazy(() => import("./pages/user-accounts"));
const CreateAccount = lazy(() => import("./pages/create-account"));

const Dashboard = lazy(() => import("./pages/dashboard"));
const Drive = lazy(() => import("./pages/drive"));
const Posts = lazy(() => import("./pages/posts"));
const AdsAssistant = lazy(() => import("./pages/ads-assistant"));
const GenerateAds = lazy(() => import("./pages/generate-ads"));
const Crm = lazy(() => import("./pages/crm"));
const Support = lazy(() => import("./pages/support"));
const Profile = lazy(() => import("./pages/profile"));
const Tutorials = lazy(() => import("./pages/tutorials"));
const Metrics = lazy(() => import("./pages/metrics"));
const NewUser = lazy(() => import("./pages/new-user"));
const ConnectSuccess = lazy(() => import("./pages/connect-success"));
const ConnectFailure = lazy(() => import("./pages/connect-failure"));
const CreateCampaign = lazy(() => import("./pages/create-campaign"));
const EditCampaign = lazy(() => import("./pages/edit-campaign"));
// const Pricing = lazy(() => import("./pages/pricing"));
const Checkout = lazy(() => import("./pages/checkout"));
const BillingPage = lazy(() => import("./components/profile/billingPage"));
const BusinessDetailsPage = lazy(() => import("./components/profile/businessDetailsPage"));
const ProfileAiAssistant = lazy(() => import("./components/profile/aiAssistant"));
const ProfilePage = lazy(() => import("./components/profile/profilePage"));
const ForgotPassword = lazy(() => import("./pages/forgot-password"));
const ResetPassword = lazy(() => import("./pages/reset-password"));
const Preferences = lazy(() => import("./components/profile/preferences"));

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Global />
      <Toaster position="bottom-center" />
      <ReactTooltip id="tooltip" className="z-50 max-w-72 text-wrap" place="right" />

      <Suspense fallback={<FullWindowLoader />}>
        <BrowserRouter>
          <SentryRoutes>
            <Route element={<Unauth />}>
              <Route element={<Login />} path={paths.login} />
              <Route element={<Signup />} path={paths.signup} />
              <Route element={<ForgotPassword />} path={paths.forgotPassword} />
              <Route element={<ResetPassword />} path={paths.resetPassword} />
            </Route>

            <Route element={<Auth />}>
              <Route element={<Onboarding />}>
                <Route element={<TwoPanel primary={<NewUser />} secondary={<Secondary />} />} path={paths.newUser} />
                <Route
                  element={<TwoPanel primary={<CreateAccount />} secondary={<Secondary />} />}
                  path={paths.createAccount}
                />
                <Route element={<Checkout />} path={paths.checkout} />
                <Route
                  element={<TwoPanel primary={<UserAccounts />} secondary={<Secondary />} />}
                  path={paths.accounts}
                />
              </Route>
              <Route element={<PayingUser />}>
                <Route element={<Account />}>
                  <Route element={<Dashboard />} path={paths.dashboard} />
                  <Route element={<Drive />} path={`${paths.drive}/:location?/media?/:mediaId?`} />
                  <Route element={<Posts />} path={`${paths.posts}/:postId?`} />
                  <Route element={<AdsAssistant />} path={paths.adsAssistant} />
                  <Route element={<GenerateAds />} path={paths.generateAds} />
                  <Route element={<CreateCampaign />} path={paths.createCampaign} />
                  <Route element={<EditCampaign />} path={paths.editCampaign} />
                  <Route element={<Metrics />} path={paths.metrics} />
                  <Route element={<Crm />} path={paths.crm} />
                  <Route element={<Support />} path={paths.support} />
                  <Route element={<Tutorials />} path={paths.tutorials} />
                  <Route element={<Profile />}>
                    <Route element={<ProfilePage />} path={paths.profileAccount} />
                    <Route element={<BusinessDetailsPage />} path={paths.profileBusinessDetails} />
                    <Route element={<ProfileAiAssistant />} path={paths.profileAiAssistant} />
                    <Route element={<BillingPage />} path={paths.profileBilling} />
                    <Route element={<Preferences />} path={paths.preferences} />
                  </Route>
                </Route>
              </Route>
            </Route>

            <Route element={<ConnectSuccess />} path={`${paths.connectSuccess}/:platform?`} />
            <Route element={<ConnectFailure />} path={`${paths.connectFailure}/:platform?`} />

            <Route element={<Navigate replace to={paths.login} />} path="*" />
          </SentryRoutes>
        </BrowserRouter>
      </Suspense>
    </ThemeProvider>
  );
};

const SentryApp = Sentry.withProfiler(App);

export default SentryApp;
