import { useMutation, useReactiveVar } from "@apollo/client";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Dialog, Transition } from "@headlessui/react";
import _ from "lodash";
import { Fragment, useEffect, useMemo, useState } from "react";

import {
  PLATFORM_CHILD_TARGETS_BY_PLATFORM,
  SELECTED_PLATFORM,
  SELECTED_PLATFORM_TARGET,
} from "../../graphql/localStates/platform";
import { DISCONNECT_PLATFORM_TARGETS } from "../../graphql/mutations/platform";
import { GET_PLATFORM_TARGETS } from "../../graphql/queries/platform";
import useDeepCompareEffect from "../../hooks/use-deep-compare-effect";
import useDeepCompareMemo from "../../hooks/use-deep-compare-memo";
import { colors, elevation } from "../../styles/theme";
import CustomIcon from "../common/custom-icon";
import { ClickableOutlinedIcon, OutlinedIcon } from "../common/icons";
import Menu from "../common/menus";
import { BodySmallBold, Caption, Mini } from "../common/typography";

const PLATFORM_TARGET_CARD_PLATFORM_SVGS = {
  facebook: { color: "custom", shade: 700 },
  google: { color: "multi" },
  instagram: { color: "multi" },
  linkedin: { color: "custom" },
  mindbody: { color: "black" },
  tiktok: { color: "black" },
  twitter: { color: "custom", shade: 700 },
  gymsales: { color: "multi" },
  gymleads: { color: "multi" },
};

const Card = styled.div`
  padding: 16px;
  background-color: ${colors.blue[100]};
  border-radius: 8px;
  box-shadow: ${elevation[200]};
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  cursor: pointer;

  ${Caption} {
    color: ${colors.gray[700]};
  }

  ${(props) =>
    props.isSelected &&
    css`
      background-color: ${colors.blue[300]};

      ${BodySmallBold} {
        color: ${colors.blue[1000]};
      }

      ${Caption} {
        color: ${colors.gray[900]};
      }
    `}

  &:last-of-type {
    margin-bottom: 8px;
  }
`;

const CardMain = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
`;

const CardContent = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const TextDiv = styled.div`
  width: 100%;
  min-height: 36px;
  margin-left: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CardButton = styled.button`
  cursor: pointer;
  margin-bottom: -8px;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: ${colors.gray[9000]};

  ${(props) =>
    !props.isSelected &&
    css`
      &:hover * {
        color: ${colors.gray[1000]};
      }
    `}

  &:active:hover * {
    color: ${colors.gray[1100]};
  }

  ${(props) =>
    props.isSelected &&
    css`
      color: ${colors.blue[1000]};

      &:hover * {
        color: ${colors.blue[1100]};
      }
    `}
`;

const CardTitle = styled(BodySmallBold.withComponent("div"))`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TargetCard = ({ platform, platformTargets, possibleInstagramParents, connect }) => {
  const selectedPlatform = useReactiveVar(SELECTED_PLATFORM);
  const selectedPlatformTarget = useReactiveVar(SELECTED_PLATFORM_TARGET);
  const targetsByPlatform = useReactiveVar(PLATFORM_CHILD_TARGETS_BY_PLATFORM);
  const [isSelected, setIsSelected] = useState(false);
  const [parentTargets, setParentTargets] = useState([]);
  const [childTargetCounts, setChildTargetCounts] = useState({
    enabled: 0,
    total: 0,
  });
  const [showMenu, setShowMenu] = useState(false);

  useDeepCompareEffect(() => {
    if (platformTargets.length === 0 && possibleInstagramParents) return;
    const parentTargets = [];
    const outsideParents = new Map();
    let enabledChildTargetCounts = 0;
    let totalChildTargetCounts = 0;
    const targetsByType = new Map();
    for (const target of platformTargets) {
      if (platform === "instagram" && target.parentId) {
        if (possibleInstagramParents.has(target.parentId)) {
          outsideParents.set(target.parentId, possibleInstagramParents.get(target.parentId));
        }
      }
      if (!target.parentId) parentTargets.push(target);
      if (target.parentId) {
        if (target.isEnabled) {
          enabledChildTargetCounts++;
        }
        totalChildTargetCounts++;
        const type = _.camelCase(target.platformTargetType);
        if (!targetsByType.has(type)) targetsByType.set(type, []);
        targetsByType.get(type).push(target);
      }
    }
    targetsByPlatform.set(platform, targetsByType);
    PLATFORM_CHILD_TARGETS_BY_PLATFORM(new Map(targetsByPlatform.entries()));
    setParentTargets([...parentTargets, ...outsideParents.values()]);
    setChildTargetCounts({
      enabled: enabledChildTargetCounts,
      total: totalChildTargetCounts,
    });
  }, [platformTargets, possibleInstagramParents]);

  const onPlatformSelect = (parentTargetId) => {
    SELECTED_PLATFORM(selectedPlatform === platform ? null : platform);
    SELECTED_PLATFORM_TARGET(
      !selectedPlatformTarget && selectedPlatformTarget === parentTargetId ? null : parentTargetId
    );
  };

  const hasDeauthorized = useDeepCompareMemo(
    () => platformTargets.some((target) => target.isDeauthorized && !target.isDisconnected),
    [platformTargets]
  );

  const singleParentTarget = useDeepCompareMemo(() => {
    return (
      parentTargets.find((target) => target.platform === (platform === "instagram" ? "facebook" : platform))?.id || null
    );
  }, [parentTargets, platform]);

  return (
    <div>
      <Card
        isSelected={platform === selectedPlatform}
        tabIndex={0}
        onClick={() => {
          setShowMenu((a) => !a);
        }}
      >
        <CardMain>
          <CardContent>
            <CustomIcon
              color={PLATFORM_TARGET_CARD_PLATFORM_SVGS[platform].color}
              name={platform}
              shade={PLATFORM_TARGET_CARD_PLATFORM_SVGS[platform].shade ?? null}
            />
            <TextDiv>
              <CardTitle>
                {parentTargets.length === 1 ? parentTargets[0].name : `${parentTargets.length} accounts`}
                {hasDeauthorized && (
                  <OutlinedIcon
                    data-tooltip-id="tooltip"
                    data-tooltip-content="One or more of your Facebook integrations has expired. Please reconnect now to avoid any missing data."
                    color="red"
                    name="sync_problem"
                    shade={800}
                    size="20px"
                  />
                )}
              </CardTitle>
              <Caption>
                {!["mindbody", "gymsales", "gymleads"].includes(platform)
                  ? `${childTargetCounts.enabled}/${childTargetCounts.total} child accounts enabled`
                  : "All accounts enabled"}
              </Caption>
            </TextDiv>
          </CardContent>

          {childTargetCounts.total > 0 && parentTargets.length < 2 && singleParentTarget && (
            <ClickableOutlinedIcon
              color="blue"
              isSelected={platform === selectedPlatform && selectedPlatformTarget === singleParentTarget}
              name={
                platform === selectedPlatform && selectedPlatformTarget === singleParentTarget
                  ? "arrow_circle_left"
                  : "arrow_circle_right"
              }
              onClick={(e) => {
                e.stopPropagation();
                onPlatformSelect(singleParentTarget);
              }}
              shade={800}
            />
          )}
        </CardMain>
        <CardButton
          isSelected={isSelected || showMenu}
          onClick={(e) => {
            e.stopPropagation();
            setShowMenu((prev) => {
              if (!prev) {
                return !prev;
              }
            });
          }}
        >
          <BodySmallBold>Manage account{parentTargets.length > 1 ? "s" : ""}</BodySmallBold>
          <OutlinedIcon name="expand_more" />
        </CardButton>
      </Card>

      {showMenu && <ManageAccounts connect={connect} parentTargets={parentTargets} platform={platform} />}
    </div>
  );
};

const ManageAccountsMenu = styled(Menu)`
  width: 100%;
  margin-top: 12px;
  z-index: 1;
  & button:last-of-type {
    border-radius: 0px 0px 8px 8px;
  }
`;

const ActionMenuItem = styled(Menu.Item)`
  padding-block: 15px;
`;

const MenuText = styled.div`
  text-align: left;

  ${Mini} {
    margin-top: 4px;
    color: ${colors.gray[800]};
  }
`;

const RedMenuText = styled(MenuText)`
  color: ${colors.red[800]};
  ${(props) => props.isDisabled && `color: ${colors.gray[600]};`}
`;

const DisconnectIcon = styled(OutlinedIcon)`
  ${(props) => props.isDisabled && `color: ${colors.gray[600]};`}
`;

const MenuIcons = styled.div`
  display: flex;
  gap: 8px;
`;

const TitleCaseSpan = styled.span`
  text-transform: capitalize;
`;

const ManageAccounts = ({ platform, parentTargets, connect }) => {
  const selectedPlatform = useReactiveVar(SELECTED_PLATFORM);
  const selectedPlatformTarget = useReactiveVar(SELECTED_PLATFORM_TARGET);
  const [disconnectingIds, setDisconnectingIds] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const [disconnectPlatformTargets, { loading }] = useMutation(DISCONNECT_PLATFORM_TARGETS, {
    awaitRefetchQueries: true,
    fetchPolicy: "network-only",
    refetchQueries: [GET_PLATFORM_TARGETS],
  });

  const selectTarget = (parentTargetId) => {
    SELECTED_PLATFORM(platform);
    SELECTED_PLATFORM_TARGET(selectedPlatformTarget === parentTargetId ? null : parentTargetId);
  };

  const setDisconnectParentTarget = async (id) => {
    setDisconnectingIds((prev) => [id]);
  };

  const setDisconnectAll = async () => {
    const ids = parentTargets.map((target) => target.id);
    setDisconnectingIds(ids);
  };

  const disconnect = async () => {
    await disconnectPlatformTargets({
      variables: {
        ids: disconnectingIds,
      },
    });
    setDisconnectingIds([]);
    SELECTED_PLATFORM(null);
    closeModal();
  };

  const closeModal = () => {
    setIsOpen(false);
    setDisconnectingIds([]);
  };

  const openModal = (parentTargetId) => {
    if (parentTargetId) setDisconnectParentTarget(parentTargetId);
    else setDisconnectAll();
    setIsOpen(true);
  };

  return (
    <ManageAccountsMenu>
      {parentTargets.map((target) => (
        <Menu.ItemDiv backgroundColor="blue" key={target.id} shade={100}>
          <MenuText>
            {target.name}
            {(target.email || target.externalId) && <Mini>{target.email || target.externalId}</Mini>}
          </MenuText>

          <MenuIcons>
            {platform !== "instagram" && (
              <ClickableOutlinedIcon
                color="red"
                isDisabled={disconnectingIds.includes(target.id) || loading}
                name="link_off"
                onClick={() => openModal(target.id)}
                shade={800}
              />
            )}

            {!["mindbody", "gymsales", "gymleads"].includes(platform) && (
              <ClickableOutlinedIcon
                color="blue"
                isSelected={platform === selectedPlatform && selectedPlatformTarget === target.id}
                name={
                  platform === selectedPlatform && selectedPlatformTarget === target.id
                    ? "arrow_circle_left"
                    : "arrow_circle_right"
                }
                onClick={() => selectTarget(target.id)}
                shade={800}
              />
            )}
          </MenuIcons>
        </Menu.ItemDiv>
      ))}

      {!["mindbody", "gymsales", "gymleads"].includes(platform) && (
        <ConnectButton connect={connect} platform={platform} />
      )}

      {platform !== "instagram" && (
        <DisconnectAllButton
          disconnectAll={() => openModal()}
          isDisconnecting={disconnectingIds.length > 0 || loading}
          platform={platform}
        />
      )}

      <div>
        <>
          <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black/25" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Disconnect {platform} account
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-700">
                          Are you sure you want to disconnect this {platform} account? This will disconnect all the
                          associated child accounts as well, This action cannot be undone. you must reconnect to access
                          your data.
                        </p>
                      </div>

                      <div className="mt-4 flex gap-1">
                        <button
                          type="button"
                          disabled={loading}
                          className="inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:grayscale"
                          onClick={() => disconnect()}
                        >
                          Disconnect
                        </button>
                        <button
                          type="button"
                          className="inline-flex justify-center rounded-md border border-transparent bg-gray-500 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                          onClick={closeModal}
                        >
                          Cancel
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        </>
      </div>
    </ManageAccountsMenu>
  );
};

const ConnectButton = ({ platform, connect }) => {
  return (
    <ActionMenuItem backgroundColor="blue" onClick={() => connect(platform)} shade={100}>
      <MenuText>
        Connect <TitleCaseSpan>{platform}</TitleCaseSpan> accounts
      </MenuText>
      <OutlinedIcon color="gray" name="link" shade={800} />
    </ActionMenuItem>
  );
};

const DisconnectAllButton = ({ platform, disconnectAll, isDisconnecting }) => {
  return (
    <ActionMenuItem backgroundColor="blue" disabled={isDisconnecting} onClick={disconnectAll} shade={100}>
      <RedMenuText isDisabled={isDisconnecting}>
        Disconnect all <TitleCaseSpan>{platform}</TitleCaseSpan> accounts
      </RedMenuText>
      <DisconnectIcon color="red" isDisabled={isDisconnecting} name="link_off" shade={800} />
    </ActionMenuItem>
  );
};

export default TargetCard;
