import { gql } from "@apollo/client";

export const CHECK_MINDBODY_SITE_ACTIVATION = gql`
  query checkMindbodySiteActivation($siteId: ID!) {
    checkMindbodySiteActivation(siteId: $siteId) {
      isActivated
      isValid
      siteId
    }
  }
`;

export const CHECK_MINDBODY_PAYWALL_ACTIVATION = gql`
  query checkMindbodyPaywallActivation($accountId: ID!, $siteId: ID!) {
    checkMindbodyPaywallActivation(accountId: $accountId, siteId: $siteId) {
      isActivated
      siteId
    }
  }
`;
